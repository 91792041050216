<template>
  <!--客户案例-->
  <div class="product">
    <div class="top">
      <top :selected="selected" :pic="pic"></top>
    </div>
    <div class="topCusIntroduce">
      <topCusIntroduce
        :icon="icon"
        :percent="percent"
        :titleStr="titleStr"
        :index="index"
      ></topCusIntroduce>
    </div>
    <div class="homeContent">
      <div class="productTab" id="productTab">
        <productTab
          :timeData="titleStr"
          :index="index"
          @fatherMethod="getTab"
        ></productTab>
      </div>
      <!-- 全渠道营销与私域流量管理 -->
      <div class="tabContent tabContent5" id="5" v-show="index == 5">
        <div class="orderRouting">
          <orderRouting></orderRouting>
        </div>
        <div class="stockTaking">
          <stockTaking></stockTaking>
        </div>
        <div class="accountSys">
          <accountSys></accountSys>
        </div>
        <div class="guideProcess">
          <guideProcess></guideProcess>
        </div>
      </div>
      <div class="bottomIntroduce">
        <bottomIntroduce @childByValue="childByValue"></bottomIntroduce>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import top from "@/components/top.vue";
import topCusIntroduce from "@/components/topCusIntroduce.vue";
import productTab from "@/components/productTab.vue";

import passIntroduce from "@/components/product/passIntroduce.vue";
import passData from "@/components/product/passData.vue";
import passAdvantages from "@/components/product/passAdvantages.vue";
import passSolution from "@/components/product/passSolution.vue";

import xlsIntroduce from "@/components/product/xlsIntroduce.vue";
import xlsAdvSol from "@/components/product/xlsAdvSol.vue";
import xlsPoints from "@/components/product/xlsPoints.vue";
import retailTreasures from "@/components/product/retailTreasures.vue";
import xlsProducts from "@/components/product/xlsProducts.vue";

import productIntroduce from "@/components/product/productIntroduce.vue";
import fiveAdvantages from "@/components/product/fiveAdvantages.vue";
import oaAdvantages from "@/components/product/oaAdvantages.vue";
import oaCapacity from "@/components/product/oaCapacity.vue";

import dpIntroduce from "@/components/product/dpIntroduce.vue";
import productAdvantages from "@/components/product/productAdvantages.vue";
import productFeature from "@/components/product/productFeature.vue";
import scenario from "@/components/product/scenario.vue";

import biIntroduce from "@/components/product/biIntroduce.vue";
import analyticalMethod from "@/components/product/analyticalMethod.vue";
import businessGrowth from "@/components/product/businessGrowth.vue";
import applicationValues from "@/components/product/applicationValues.vue";

import orderRouting from "@/components/product/orderRouting.vue";
import stockTaking from "@/components/product/stockTaking.vue";
import accountSys from "@/components/product/accountSys.vue";
import guideProcess from "@/components/product/guideProcess.vue";

import bottomIntroduce from "@/components/bottomIntroduce.vue";

export default {
  name: "product",
  data() {
    return {
      selected: "productPass",
      pic: require("../assets/Images/cubg.png"),
      icon: require("../assets/Images/cpbg.png"),
      percent: 75,
      index: 5,
      index1: 0,
      titleStr: [
        {
          id: 0,
          name: this.$t("快速开发平台"),
          detail: '"一个公司、一套业务系统"',
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 1,
          name: this.$t("新零售一体化系统"),
          detail: '"一个公司、一套业务系统"',
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 2,
          name: this.$t("POS+智慧收银系统"),
          detail: "打造品牌的超级门店   实现其全数字化经营",
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 3,
          name: this.$t("魔数智屏"),
          detail: "重新定义数据可视化   提升客户数据洞悉能力",
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 4,
          name: this.$t("零售数字化移动BI"),
          detail: '让企业关键经营数据尽在"掌"握',
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 5,
          name: this.$t("全渠道营销与私域流量管理"),
          detail: "全渠道统一平台管理   保障各渠道无缝衔接协同",
          icon: require("../assets/Images/cpbg.png"),
        },
      ],
    };
  },
  components: {
    top,
    topCusIntroduce,
    productTab,
    orderRouting,
    stockTaking,
    accountSys,
    guideProcess,
    bottomIntroduce,
  },
  methods: {
    getTab(data) {
      //console.log(data);
      this.index = data;
    },
    childByValue(id) {
      console.log(id, "childByValue父");
      this.index = id;
    },
  },
  mounted() {
    localStorage.setItem("toId", this.index);
    this.index = localStorage.getItem("toId");
  },
  watch: {
    index(newval, oldval) {
      console.log("newval", newval);
      console.log("oldval", oldval);
    localStorage.setItem("toId", newval);
     // this.$router.push("/product");
    },
    '$i18n.locale': function (newVal) {
        this.titleStr= [
        {
          id: 0,
          name: this.$t("快速开发平台"),
          detail: '"一个公司、一套业务系统"',
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 1,
          name: this.$t("新零售一体化系统"),
          detail: '"一个公司、一套业务系统"',
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 2,
          name: this.$t("POS+智慧收银系统"),
          detail: "打造品牌的超级门店   实现其全数字化经营",
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 3,
          name: this.$t("魔数智屏"),
          detail: "重新定义数据可视化   提升客户数据洞悉能力",
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 4,
          name: this.$t("零售数字化移动BI"),
          detail: '让企业关键经营数据尽在"掌"握',
          icon: require("../assets/Images/cpbg.png"),
        },
        {
          id: 5,
          name: this.$t("全渠道营销与私域流量管理"),
          detail: "全渠道统一平台管理   保障各渠道无缝衔接协同",
          icon: require("../assets/Images/cpbg.png"),
        },
      ]
   }
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar-track-piece {
  //滚动条凹槽的颜色，还可以设置边框属性
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  //滚动条的宽度
  width: 9px;
  height: 9px;
  z-index: 999;
}
::-webkit-scrollbar-thumb {
  //滚动条的设置
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
::-webkit-scrollbar {
  //display: none;
}
.product {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  .top {
    width: 100%;
    height: 3.5rem;
    position: fixed;
    z-index: 100;
  }
  .topCusIntroduce {
    width: 100%;
    height: 37.5rem;
  }
  .homeContent {
    width: 100%;
    background-color: #fff;
    overflow-y: hidden;
    //height: 100%;
    // background-image: url("../assets/Images/bg.png");
    // //background-position: 0%;
    // background-repeat: repeat;
    // background-size: 100%;
    .productTab {
      width: 100%;
      height: 10rem;
      padding-top: 2rem;
    }
    .tabContent0 {
      background-image: url("../assets/Images/ks_bg.png");
      background-repeat: repeat;
      background-size: 100%;
    }
    .tabContent1 {
      background-image: url("../assets/Images/xls/drp_bg.png");
      background-repeat: repeat;
      background-size: 100%;
    }
    .tabContent2 {
      background-image: url("../assets/Images/bg.png");
      background-repeat: repeat;
      background-size: 100%;
    }
    .tabContent3 {
      background-image: url("../assets/Images/dp_bg.png");
      background-repeat: repeat;
      background-size: 100%;
    }
    .tabContent4 {
      background-image: url("../assets/Images/bi_bg.png");
      background-repeat: repeat;
      background-size: 100%;
    }
    .tabContent5 {
      background-image: url("../assets/Images/bi_bg.png");
      background-repeat: repeat;
      background-size: 100%;
    }
    .tabContent {
      width: 100%;
      .passIntroduce {
        width: 100%;
        height: 30rem;
        margin-top: 0rem;
      }
      .passData {
        width: 100%;
        height: 10rem;
      }
      .productIntroduce {
        width: 100%;
        height: 30rem;
        margin-top: 0rem;
      }
      .passAdvantages {
        width: 100%;
        height: 30rem;
        padding: 1rem 0 5rem 0;
        background-color: #fafbfe;
      }
      .passSolution {
        width: 100%;
        height: 20rem;
        margin-bottom: 4rem;
      }
      .xlsIntroduce {
        width: 100%;
        height: 30rem;
      }
      .xlsAdvSol {
        width: 100%;
        height: 40rem;
      }
      .xlsPoints {
        width: 100%;
        height: 40rem;
      }
      .retailTreasures {
        width: 100%;
        height: 40rem;
      }
      .xlsProducts {
        width: 100%;
        //height: 40rem;
      }
      .fiveAdvantages {
        width: 100%;
        height: 30rem;
        margin-top: 4rem;
        margin-bottom: 4rem;
      }
      .oaAdvantages {
        width: 100%;
        height: 40rem;
        margin-bottom: 4rem;
      }
      .oaCapacity {
        width: 100%;
        height: 20rem;
        margin-bottom: 4rem;
      }
      .dpIntroduce {
        width: 100%;
        height: 30rem;
      }
      .productAdvantages {
        width: 100%;
        height: 40rem;
      }
      .productFeature {
        width: 100%;
        height: 30rem;
      }
      .Scenario {
        width: 100%;
        height: 40rem;
      }
      .biIntroduce {
        width: 100%;
        height: 30rem;
      }
      .businessGrowth {
        width: 100%;
        height: 40rem;
        margin-top: 10rem;
      }
      .analyticalMethod {
        width: 100%;
        height: 40rem;
        margin-top: 10rem;
      }
      .applicationValues {
        width: 100%;
        height: 40rem;
        margin-top: 10rem;
        margin-bottom: 5rem;
      }
      .orderRouting {
        width: 100%;
        height: 40rem;
      }
      .stockTaking {
        width: 100%;
        height: 40rem;
        margin-top: 4rem;
      }
      .accountSys {
        width: 100%;
        height: 40rem;
        margin-bottom: 10rem;
      }
      .guideProcess {
        width: 100%;
        height: 40rem;
      }
    }
  }
}
</style>